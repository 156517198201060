import React, { Component } from 'react';
import styled from 'styled-components';
import ComponentStyles from '../../brand-styles/components/Modals/Modal.styles';
import { breakpoints, colors } from '../../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';

const ModalDiv = styled.div`
  background: white;
  color: black;
  position: absolute;
  top: 130px;
  z-index: 202;
  @media (max-width: ${breakpoints.mobileMax}) {
    top: 130px;
  }
  .close-button {
    position: absolute;
    right: 0;
    border: none;
    background: transparent;
    color: black;
  }
`;

const BackDrop = styled.div`
  background: rgba(0, 0, 0, 0.65);
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 201;

  &.show {
    display: flex;
    justify-content: center;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const PdModal = ({ activeModal, isModalOpen, hideModal, children }) => {
  return (
    <BackDrop className={isModalOpen || activeModal ? 'show' : ''}>
      <ModalDiv className="modal-box">
        <button className="close-button" onClick={hideModal} type="button">
          {/* x */}
          <FontAwesomeIcon
            className="close"
            icon={faTimes}
            style={{ width: '20px', height: '20px' }}
          />
        </button>
        {children}
      </ModalDiv>
    </BackDrop>
  );
};

export default PdModal;
