import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCaretUp,
  faPlus,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import { faPlayCircle, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons';
import ComponentStyles from '../brand-styles/templates/recipe-detail.styles';
// import { Link, animateScroll as scroll } from "../node_modules/react-scroll";
import ReactHtmlParser from 'react-html-parser';

//import components
import Heading from '../components/Heading';
import StyledLink from '../components/StyledLink';
import ReviewsCount from '../components/Reviews/ReviewsCount';
import placeHolderImage from '../images/placeholder.png';
import RegularButton from '../components/RegularButton';
import WhiteButton from '../components/WhiteButton';
import ShareWidget from '../components/ShareWidget';
import WhereToBuy from '../components/WhereToBuy';
import RecipeSearchWidget from '../components/RecipeSearchWidget';
import RecipeCard from '../components/RecipeCard';
import ShoppingListDrawer from '../components/ShoppingListDrawer';
import { breakpoints, colors, fonts } from '../style-utilities/variables';
import { ShoppingListStateConsumer } from '../context/ShoppingListContext';
import PlaceHolderImage from '../images/placeholder.png';
import SEO from '../components/SEO';

import FlyoutBasic from '../components/FlyoutBasic';
import FlyoutContentNotAuthed from '../components/FlyoutContentNotAuthed';

import FavoritesService from '../services/favorites.service';
import UserService from '../services/user.service';
import CraftPaperImage from '../images/craft-paper.png';

// Print
import RecipeDetailPrint from '../components/RecipeDetailPrint';
import { PrintTool } from 'react-print-tool';

import ReviewsSection, {
  ReviewSectionStyle
} from '../components/Reviews/ReviewsSection';
import { timeout } from 'q';
import { areRangesOverlapping } from 'date-fns';

import PdModal from '../components/ProductDetail/PdModal';
import { layer } from '@fortawesome/fontawesome-svg-core';

const TopSection = styled.div`
  #hero-section {
    .ImgContainer {
      height: 400px;
      width: 100%;
      background-image: url(${props => props.backgroundImage});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .hero-recipe {
      position: relative;
      text-align: center;
      background: peru;
      background-image: url(${CraftPaperImage});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 20px 15px;
      color: white;
      .detail {
        margin-top: 0;
        p {
          margin-bottom: 15px;
          padding: 0 50px;
        }
      }
      .time-info img {
        height: 60px;
      }
      h1 {
        margin: 0;
        font-size: 36pt;
      }
      hr,
      a {
        color: white;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    #hero-section {
      height: 600px;
      align-items: center;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 60% 40%;
      grid-template-columns: 60% 40%;
      grid-template-areas: 'img hero-recipe';
      max-width: 1400px;
      margin: 0 auto;
      .ImgContainer {
        height: 600px;
        position: absolute;
        right: 0;
      }
      .hero-recipe {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-area: hero-recipe;
        padding: 15px;
        z-index: 1;
        border-radius: 5px;
        margin-right: 60px;
        text-align: left;

        .detail p {
          padding: 0;
        }
      }
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #hero-section {
      .hero-recipe {
        top: 25%;
      }
    }
  }

  .review-watch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    a {
      text-decoration: none;
      margin-left: 14px;
    }
  }

  #shopping-list-open {
    z-index: 200;
    color: ${colors.primary};
    transform: rotate(-90deg);
    right: -90px;
    top: 50%;
    position: fixed;
    cursor: pointer;
    border: none;
    background: white;
    padding: 9px 14px 14px;
    border-top: 1px solid ${colors.primary};
    border-right: 1px solid ${colors.primary};
    border-left: 1px solid ${colors.primary};
  }

  .hero-recipe-info {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding: 6px;
    div {
      flex: 1;
      padding: 0.5em; /* add some padding ?*/
      text-align: center;
      border-right: 1px solid white;
    }
    div:last-child {
      border: none;
    }
    p {
      margin: 0;
    }
    img {
      height: 80px;
    }
  }

  .widget-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    flex-direction: column;
    position: relative;
    ul {
      display: flex;
    }
    .icon {
      max-height: 18px;
      color: ${colors.primary};
      margin-right: 6px;
    }
    .a2a_button_pinterest,
    .pin-link {
      text-decoration: none !important;
    }

    .button {
      border: 1px solid lightgray;
      margin-top: 12px;
      padding: 12px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.primary};
      :hover {
        opacity: 0.8;
      }
      a:active,
      a:visited,
      a {
        text-decoration: none !important;
      }
      svg {
        width: 25px;
        /* fill: ${colors.primary}; */
        /* color: #163571; */
      }
      cursor: pointer;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .widget-section {
      flex-direction: row;
      .button {
        margin: 0 10px;
      }
    }
  }
`;

const RelatedSection = styled.div`
  text-align: center;
  margin: 40px 0 70px;
  h2 {
    font-size: 36px;
    margin-bottom: 0.2em;
    font-family: Poppins;
    color: rgb(190, 13, 52);
    margin: .67em 0;
  }


  #related-recipes {
    max-width: 1600px;
    margin: 40px auto 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media (-ms-high-contrast: none) {
      justify-content: space-around; // IE11 doesn't know space-evenly
    }
    @supports (-ms-ime-align: auto) {
      /* Edge only */
      justify-content: space-around;
    }
  }
  .anchor-position {
    display: block;
    content: ' ';
    margin-top: -165px;
    position: relative;
    height: 1px;
    top: 105px;
    visibility: hidden;
    pointer-events: none;
  }
  .swiper-container {
    margin: 10px auto;
  }
  .swiper-pagination-bullets {
    /* bottom: 20px !important; */
  }
`;

const InstructionSection = styled.div`
  background: ${colors.primary};
  width: 100%;
  text-align: center;
  padding: 40px 15px 60px;
  color: white;
  text-align: center;
  .title-instructions {
    margin-bottom: 0;
    color: #ffffff;
    margin: .67em 0;
    font-weight: bold;
  }
  ul {
    list-style: none;
    padding: 0;
  }

  li p {
    display: inline-block;
    margin: 0 10px;
  }
  hr {
    margin-bottom: 28px;
  }
  h4 {
    margin-bottom: 0;
  }
  #tags {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    margin-bottom: 20px;
    #tag {
      background: white;
      color: ${colors.primary};
      font-style: italic;
      font-size: 14px;
      margin-right: 5px;
      margin-top: 5px;
      padding: 5px 10px;
    }
  }
  #instruction-container {
    li p {
      margin-left: 31px;
      margin-top: 10px;
    }
    li input {
      margin-right: 10px;
    }
    li {
      margin-bottom: 20px;
    }
    .favorite-button {
      position: relative;
      .white-button {
        width: 100%;
      }
      .inline {
        position: absolute;
      }
    }
  }
  .left-align {
    text-align: left;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    .pin-link {
      text-decoration: none !important;
    }
  }
  .button {
    width: 100%;
  }
  @media (min-width: 500px) {
    .button {
      width: auto;
    }
    .button-container {
      flex-direction: row;
      margin: 0 auto;
      max-width: 700px;
      justify-content: space-between;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    #instruction-container {
      width: 80%;
      max-width: ${breakpoints.maxContainer};
      margin: 0 auto;
    }
  }
`;

const RecipeSection = styled.div`
  .ingredient-container {
    width: 100%;
    #ingredient-section {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
      width: 80%;
      padding: 40px 0;
      margin: 0 auto;
      h2 {
        color: ${colors.black};
      }
      .ingredients {
        -ms-grid-column: 1;
        padding: 20px;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            border-bottom: 1px solid lightgray;
            padding: 10px 0;
            margin: 0 12px;
          }
        }
        .add-to-list svg {
          width: 13px;
          height: 13px;
        }
        button {
          color: ${colors.primary};
          background: white;
          border: 1px solid ${colors.primary};
          padding: 12px 30px;
          margin-top: 18px;
          :hover {
            opacity: 0.8;
            transition: opacity 0.3s ease-out;
          }
        }

        #deselect-button {
          border: none;
          border-bottom: 1px solid ${colors.primary};
          color: ${colors.primary};
          background: transparent;
          cursor: pointer;
          padding: 0;
          margin-top: 24px;
        }
      }
      div.ps-widget {
        display: none !important;
      }
      .product-image {
        -ms-grid-column: 2;
        text-align: center;
        padding: 30px;
        .product-image-container {
          margin: auto;
          a {
            text-decoration: none;
          }
          img {
            width: 100%;
            display: block;
            margin: 20px auto;
          }
          h2 {
            font-family: ${fonts.primary}, sans-serif;
            font-size: 36pt;
          }
          #product-title {
            font-size: 20px;
            margin-bottom: 15px;
          }
          @media (min-width: ${breakpoints.mobileMax}) {
            width: 350px;
          }
        }
      }
      h4 {
        margin: 28px 0 6px;
      }
    }
  }
`;

const StyledModal = styled.div`
  min-height: 100px;
  min-width: 200px;
  padding: 20px;
  img {
    max-width: 90vw;
    max-height: 80vh;
  }
`;

/* PDF View Styles  */
const PDFView = styled.div`
  padding: 100px;
`;

const Div = styled.div`
  scroll-behavior: smooth;
  .video-button {
    color: red;
    border: none;
    background-color: transparent;
  }
  .modal-box {
    background-color: #000;
    .close-button {
      top: -20px;
      right: -15px;
      color: #fff;
    }
  }
  h4 {
    margin-top: 10px;
  }
  .sticky {
    position: fixed;
    width: 100vw;
  }
  /*CUSTOM SQUARE CHECKBOX  */
  .checkbox-button {
    cursor: pointer;
  }
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input {
    font-size: 1rem;
    line-height: 1.5;
    padding: 11px 23px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    outline: 0;
    background-color: transparent;
  }
  .checkbox-button__input {
    opacity: 0;
    position: absolute;
  }
  .checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    color: black;
    border: 2px solid #666;
  }
  .checkbox-button__input:checked + .checkbox-button__control:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background-color: black;
  }
  .checkbox-button__input:checked + .checkbox-button__control {
    border-color: black;
  }
  .checkbox-button__control {
    transform: scale(0.75);
  }

  /*CIRCLE CHECKBOX */
  #instruction-container
    .checkbox-button__input:checked
    + .checkbox-button__control:after {
    top: -2px;
    left: -2px;
    background-color: white;
  }
  /*LINE THROUGH WHEN CHECKED*/
  .steps
    .checkbox-button__input:checked
    + .checkbox-button__control
    + .checkbox-button__label {
    text-decoration: line-through;
  }
  #instruction-container .checkbox-button__control,
  #instruction-container
    .checkbox-button__input:checked
    + .checkbox-button__control:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: white;
  }
  #instruction-container .checkbox-button__control {
    border: 2px solid white;
  }
  input[type='checkbox']:checked + .checkbox-button.strike {
    color: blue;
  }

  #caret {
    width: 15px;
    fill: ${colors.primary};
  }

  @media (max-width: ${breakpoints.tablet}) {
    ${RecipeSection} {
      margin: 0;
      #ingredient-section {
        display: block;
        padding: 0 15px;
        width: 100%;
        .ingredients {
          padding: 0;
          ul {
            margin: 15px 0;
            padding: 0;
          }
        }
        .product-image {
          margin-top: 15px;
          img {
            width: 100%;
          }
        }
      }
    }

    ${RelatedSection} {
      #related-recipes {
        margin: 0;
        text-align: center;
      }
      .recipe-card {
        text-align: left;
        margin-bottom: 15px;
      }
      .button {
        margin: 10px 0;
      }

    }
    ${ReviewSectionStyle} {
      width: 100%;
      .review-content {
        display: block;
      }
    }
  }
  .recipe-sub-nav {
    z-index: 100;
    position: sticky;
    position: -webkit-sticky;
    top: 65px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    .sub-nav-container {
      overflow-x: auto;
      max-width: ${breakpoints.maxContainer};
      margin: 0 auto;
      width: 75%;
      padding: 4px 0;
      .nav-item .to-top {
        color: ${colors.primary};
        font-weight: bold;
        border-right: 1px solid lightgray;
        @media (max-width: 934px) {
          display: none;
        }
      }
      .nav-item {
        text-decoration: none;
        color: white;
        width: 140px;
        display: inline-block;
        text-align: center;
        padding: 10px 0;
      }
      .nav-item:focus,
      .nav-item:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
    @media (max-width: ${breakpoints.tablet}) {
      top: 104px;
      white-space: nowrap;
      /* display: none; */
    }
  }

  ${ComponentStyles}
`;

class RecipeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPort: 'desktop',
      isBrowser: true,
      user: {},
      searchValue: '',
      currentRecipeTags: this.props.data.wordpressWpRecipes.tags
        ? this.props.data.wordpressWpRecipes.tags.map(tag => tag.name)
        : [],
      currentRecipeSlug: this.props.data.wordpressWpRecipes.slug,
      isShopOpen: false,
      itemsToAdd: [],
      isFavorited: false,
      isAuthed: false,
      showNoAuthFlyout: null,
      pdfMode: false,
      currentUrl: '',
      previewPayload: null,
      isPreviewMode: false,
      ieStatus: false,
      isVideoModalOpen: false
    };

    this.favoriteService = new FavoritesService();
    this.userService = new UserService();

    this.psKey = process.env.PS_KEY;
  }
  siteName = this.props.siteTitle ? this.props.siteTitle : '';

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let pdfMode = params.get('pdf');

    /* Internet Explorer Sticky */
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      const stickymenu = document.getElementById('subNav');
      let stickymenuoffset = stickymenu.offsetTop;

      window.addEventListener('scroll', function(e) {
        requestAnimationFrame(function() {
          if (window.pageYOffset > stickymenuoffset) {
            stickymenu.classList.add('sticky');
          } else {
            stickymenu.classList.remove('sticky');
          }
        });
      });
    }

    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `${process.env.WP_BASE_URI}/${
        process.env.ACTIVE_BRAND
      }/wp-json/wp/v2`;

      fetch(`${BASE_URL}/recipes/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */

    const user = this.userService.getAuthenticatedUser();
    this.setState({
      user: user,
      isAuthed: user ? true : false,
      pdfMode: pdfMode ? true : false,
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile',
      currentUrl: encodeURIComponent(window.location.href)
    });
    const type = 'recipes';
    const item = this.props.data.wordpressWpRecipes;

    if (user && item) {
      this.favoriteService.getFavorite(user, item, type).then(data => {
        if (data && data.userId) {
          this.setState({
            isFavorited: true
          });
        }
      });
    }
    // this.setState({
    //   isBrowser: typeof window !== 'undefined',
    //   viewPort: window.innerWidth >= 900 ? 'desktop' : 'mobile'
    // });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    // this.setState({
    //   currentUrl: encodeURIComponent(window.location.href)
    // });
  }

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
    document
      .querySelectorAll('div.ps-lightbox')
      .forEach(e => e.parentNode.removeChild(e));
    document
      .querySelectorAll('div.ps-lightbox-background')
      .forEach(e => e.parentNode.removeChild(e));
  }

  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 900 ? 'desktop' : 'mobile'
    });
  };

  toggleModal = modal => {
    if (modal === 'VideoModal') {
      this.setState({ isVideoModalOpen: !this.state.isVideoModalOpen });
    }
  };

  isValidYouTubeUrl = url => {
    // Here i check for 'youtu' because the video urls are like this. https://youtu.be/pONCmBjVNpE
    return url.indexOf('youtu') > 0;
  };

  parseYouTubeURL = url => {
    let youTubeId;
    if (url.indexOf('?')) {
      const cleanUrl = url.split('?')[0];
      const splitUrl = cleanUrl.split('/');
      youTubeId = splitUrl[splitUrl.length - 1];
    } else {
      const splitUrl = url.split('/');
      youTubeId = splitUrl[splitUrl.length - 1];
    }
    return 'https://www.youtube.com/embed/' + youTubeId;
  };

  /**
   * Show Modal Method Video
   */
  showModal = modalName => {
    if (this.toggleModal) {
      this.toggleModal(modalName);
    } else {
      this.setState({ [`is${modalName}ModalOpen`]: true });
    }
  };
  /**
   * Hide Modal Method Video
   */
  hideModal = modalName => {
    if (this.toggleModal) {
      this.toggleModal(modalName);
    } else {
      this.setState({ [`is${modalName}ModalOpen`]: true });
    }
  };

  toggleFavorite = elId => {
    const user = this.userService.getAuthenticatedUser();
    const type = 'recipes';
    const item = this.props.data.wordpressWpRecipes;
    const confirmMsg =
      'Are you sure you want to remove this recipe from your list?';

    const doToggle = () => {
      this.favoriteService.toggleFavorite(user, item, type).then(result => {
        if (result) {
          this.setState(
            {
              isFavorited: result.slug ? true : false
            },
            () => {
              // here we get the total amount of favs
              this.favoriteService
                .getFavoritesForUser(user, type)
                .then(result => {
                  if (result) {
                    this.props.userContext.updateFavoritesCount(result.length);
                  }
                });
              if (this.props.onFavoriteCallback) {
                this.props.onFavoriteCallback();
              }
            }
          );
        }
      });
    };

    if (user && item) {
      if (this.state.isFavorited) {
        if (window.confirm(confirmMsg)) {
          doToggle();
        }
      } else {
        doToggle();
      }
    } else if (!user) {
      //show no auth flyout
      this.setState({
        isAuthed: false
      });

      this.toggleShowNoAuthFlyout(elId);
    }
  };

  toggleShowNoAuthFlyout = elId => {
    this.setState(prevState => ({
      showNoAuthFlyout: prevState.showNoAuthFlyout === elId ? null : elId
    }));
  };
  /**
   * A single Recipe and All Recipes queried from CMS
   */
  singleRecipe = this.props.data.wordpressWpRecipes;
  allRecipes = this.props.data.allWordpressWpRecipes.edges;
  allMainIngredients = this.props.data.allWordpressWpMainIngredient.edges;

  // is adding to list every timeout, not unchecking when checked again
  checkSelectedIngredients = item => {
    return (
      this.state.itemsToAdd.filter(
        i => i.id === item.replace(' ', '-').toLowerCase()
      ).length > 0
    );
  };

  /**
   * METHOD: getting selected ingredients from checkbox Inputs
   */
  selectIngredientsHandler = (event, ing) => {
    // Resource to extra ingredient from string
    // https://stackoverflow.com/questions/13204242/regular-expression-to-extract-fraction-decimal-and-number-from-a-string
    let index, newItems, item;
    newItems = [...this.state.itemsToAdd];
    // let ingredient = ing.split('/s(?![^(]*/))/');
    let ingredient = ing.split(' ');
    let number = ingredient.filter(el => el.match(/\d+([\/.]\d+)?/g)).join(' ');
    // let number = ingredient
    //   .filter(el => el.match(/^\d+(?:\.\d+)?(?:[ \t]+\d+(?:\.\d+))?/g))
    //   .join(' ');
    let string = ingredient.filter(el => el.match(/^[A-Za-z]/g)).join(' ');
    if (event.target.checked) {
      item = {
        id: ing.replace(' ', '-').toLowerCase(),
        name: string,
        quantity: number ? number : 1,
        unit: 'units'
      };
      newItems.push(item);
    } else {
      index = newItems.findIndex(
        el => el.id === ing.replace(' ', '-').toLowerCase()
      );
      newItems.splice(index, 1);
    }
    this.setState({ itemsToAdd: newItems });
    // this.setState({
    //   itemsToAdd: this.state.itemsToAdd.some(
    //     existingItem => existingItem.id === item
    //   )
    //     ? this.state.itemsToAdd.filter(existingItem => existingItem === item)
    //     : [
    //         ...this.state.itemsToAdd,
    //         {
    //           id: item.replace(' ', '-').toLowerCase(),
    //           name: item.split(item.match(/\d+([\/.]\d+)?/g)[0])[1],
    //           quantity: item.match(/\d+([\/.]\d+)?/g)[0],
    //           checked: false,
    //           unit: 'units'
    //         }
    //       ]
    // });
  };

  /**
   * Render Ingredients from CMS
   */
  renderIngredients = () => {
    if (this.singleRecipe.acf.ingredient_products) {
      const ingredient_products = this.state.isPreviewMode
        ? this.state.previewPayload.acf.ingredient_products
        : this.singleRecipe.acf.ingredient_products;

      const ingredientList = ingredient_products.map(ingredient => {
        return (
          <li key={ReactHtmlParser(ingredient.basic_ingredient)}>
            <label className="checkbox-button">
              <input
                type="checkbox"
                onChange={e =>
                  this.selectIngredientsHandler(e, ingredient.basic_ingredient)
                }
                className="checkbox-button__input"
                // id="choice1-1"
                id={ReactHtmlParser(ingredient.basic_ingredient)}
                name="choice1"
                value={ReactHtmlParser(ingredient.basic_ingredient)}
                checked={this.checkSelectedIngredients(
                  ingredient.basic_ingredient
                )}
              />
              <span className="checkbox-button__control" />
              <span className="checkbox-button__label">
                {ReactHtmlParser(ingredient.basic_ingredient)}
              </span>
            </label>
          </li>
        );
      });

      return <ul>{ingredientList}</ul>;
    }
  };

  /**
   * Render Instruction from CMS
   */
  renderInstruction = () => {
    const instructions = this.state.isPreviewMode
      ? this.state.previewPayload.acf.instructions
      : this.singleRecipe.acf.instructions;
    const instructionList = instructions.map((step, index) => (
      <li key={step.step}>
        <label className="checkbox-button strike">
          <input
            type="checkbox"
            className="checkbox-button__input"
            id="choice1-1"
            name="choice1"
          />
          <span className="checkbox-button__control" />
          <span className="checkbox-button__label">Step {index + 1}</span>
          <br />
        </label>
        <p>{ReactHtmlParser(step.step)}</p>
      </li>
    ));
    return <ul>{instructionList}</ul>;
  };

  /**
   * Render Related Recipes that share the same tags with current Recipe
   */
  renderRelatedRecipes = () => {
    const relatedRecipes = this.allRecipes
      //gets cors errors when that many requests on minute-rice - todo use paging
      .map(
        ({
          node: { wordpress_id, title, slug, tags, acf, main_ingredient }
        }) => {
          const allRecipesTags = tags ? tags.map(e => e.name) : [];
          let mainIngredientSlugs;
          if (main_ingredient) {
            mainIngredientSlugs = this.allMainIngredients.filter(el =>
              main_ingredient.some(e => e === el.node.wordpress_id)
            );
          } else {
            mainIngredientSlugs = '';
          }
          if (
            this.state.currentRecipeTags.some(el =>
              allRecipesTags.includes(el)
            ) &&
            slug !== this.state.currentRecipeSlug
          ) {
            return (
              <div key={slug}>
                <RecipeCard
                  authenticatedUser={this.props.userContext.authenticatedUser}
                  updateFavoritesCount={
                    this.props.userContext.updateFavoritesCount
                  }
                  className="recipe-card"
                  id={wordpress_id}
                  key={title}
                  title={title}
                  imageUrl={
                    acf.thumbnail === null
                      ? placeHolderImage
                      : acf.thumbnail.source_url
                  }
                  mainIngredient={
                    mainIngredientSlugs[0]
                      ? mainIngredientSlugs[0].node.slug
                      : ''
                  }
                  meal={acf.meal_course}
                  cookTime={acf.total_time}
                  slug={slug}
                />
              </div>
            );
          }
        }
      );
    return relatedRecipes;
  };

  printModal = (e, data) => {
    e.preventDefault();
    PrintTool.printFromReactComponent(
      <RecipeDetailPrint data={data} siteLogo={this.props.siteLogo} />
    );
  };

  /**
   * Onchange Handler for Recipe Search Form
   */
  onChangeHandler = e => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({ searchValue: value });
  };

  handleCheckStep(e) {
    document.getElementsByClassName(e.checkbox - button).style.textDecoration =
      'line-through';
  }
  /**
   * Submit Handler for Recipe Seach Form
   */
  submitHanler = event => {
    event.preventDefault();
    this.setState(() => {
      navigate(`/recipe-search?includes=${this.state.searchValue}`);
    });
  };
  /**
   * Method for opening Shopping List widget
   */
  openShopHandler = () => {
    this.setState({ isShopOpen: !this.state.isShopOpen });
  };

  componentDidUpdate(prevPros) {
    if (
      this.props.userContext.authenticatedUser !==
      prevPros.userContext.authenticatedUser
    ) {
      this.setState({
        isAuthed: true
      });
    }
  }

  render() {
    let title = null,
      slug = null,
      content = null,
      acf = null,
      wordpress_id = null,
      tags = null;

    let relatedRecipeArr = this.renderRelatedRecipes().filter(
      item => typeof item !== 'undefined'
    );

    /*
    Preview Mode -------------------------------------
    */
    if (this.state.isPreviewMode) {
      title = this.state.previewPayload.title.rendered;
      slug = this.state.previewPayload.slug;
      content = this.state.previewPayload.content.rendered;
      acf = this.state.previewPayload.acf;
      wordpress_id = this.state.previewPayload.id;
      tags = [{ name: 'Tag One' }, { name: 'Tag Two' }];
    } else {
      title = this.props.data.wordpressWpRecipes.title;
      slug = this.props.data.wordpressWpRecipes.slug;
      content = this.props.data.wordpressWpRecipes.content;
      acf = this.props.data.wordpressWpRecipes.acf;
      wordpress_id = this.props.data.wordpressWpRecipes.id;
      tags = this.props.data.wordpressWpRecipes.tags
        ? this.props.data.wordpressWpRecipes.tags
        : [];
    }
    /*
    END -------------------------------------
    */
    // const {
    //   title,
    //   slug,
    //   tags,
    //   acf,
    //   wordpress_id,
    //   content
    // } = this.props.data.wordpressWpRecipes;

    const params = {
      loop: true,
      type: 'bullets',
      spaceBetween: 10,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true
      }
    };

    const { isFavorited } = this.state;

    let relatedProductSlug, relatedProducts;
    if (acf.related_product) {
      relatedProductSlug = acf.related_product[0].post_name;

      relatedProducts = this.props.data.allWordpressWpProducts.edges.filter(
        item => item.node.slug === relatedProductSlug
      );
    }

    const {
      facebook_url,
      instagram_url,
      youtube_url,
      twitter_url,
      pinterest_url
    } = this.props.data.wordpressWpOptionsPage.acf;

    const socialLinks = {
      facebook: facebook_url,
      instagram: instagram_url,
      youtube: youtube_url,
      twitter: twitter_url,
      pinterest: pinterest_url
    };

    const {
      related_recipe_heading,
      related_recipe_sub_heading,
      search_widget_image
    } = this.props.data.wordpressPage.acf;

    //configure SEO info for header
    const backupContent = this.props.data.wordpressWpRecipes.content.replace(
      /<\/?[^>]+(>|$)/g,
      ''
    );
    const seoInfo = {
      psKey: true,
      title: this.props.data.wordpressWpRecipes.yoast_head_json.title,
      ogtitle: this.props.data.wordpressWpRecipes.title,
      description:
        this.props.data.wordpressWpRecipes.yoast_head_json.description !==
        ''
          ? this.props.data.wordpressWpRecipes.yoast_head_json.description
          : backupContent,
      url: this.props.location.href,
      type: 'website',
      twitter: twitter_url,
      image:
        acf.thumbnail !== null
          ? this.state.isPreviewMode
            ? acf.thumbnail.url
            : acf.thumbnail.source_url
          : PlaceHolderImage
    };
    return (
      <>
        {this.state.pdfMode ? (
          <PDFView>
            <h2>PDF View</h2>
          </PDFView>
        ) : (
          <Div>
            <SEO seoInfo={seoInfo} />

            <TopSection className="top-section">
              <div id="hero-section">
                <div
                  className="ImgContainer"
                  style={{
                    backgroundImage: `url(${
                      acf.thumbnail !== null
                        ? this.state.isPreviewMode
                          ? acf.thumbnail.url
                          : acf.thumbnail.source_url
                        : PlaceHolderImage
                    })`
                  }}
                />
                <div className="hero-recipe">
                  {this.siteName === 'mahatma' ? (
                    <div className="recipe-title">
                      <h1>{ReactHtmlParser(title)}</h1>
                      <div className="h1ChalkUnderline" />
                    </div>
                  ) : (
                    <h1>{ReactHtmlParser(title)}</h1>
                  )}

                  <div className="review-watch">
                    <ReviewsCount type={'recipes'} slug={slug} />

                    {this.isValidYouTubeUrl(acf.recipe_video_id) ? (
                      <button
                        className="video-button"
                        onClick={() => this.showModal('VideoModal')}
                      >
                        <FontAwesomeIcon
                          style={{ width: '16px', height: '16px' }}
                          icon={faPlayCircle}
                        />{' '}
                        Watch Video
                      </button>
                    ) : null}
                  </div>
                  <span className="detail">{ReactHtmlParser(content)}</span>
                  <div className="hero-recipe-info">
                    <div className="time-info">
                      {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                      <p id="prep-title">Prep Time:</p>
                      <p id="prep-content">
                        {acf.prep_time ? acf.prep_time : '-'}
                      </p>
                    </div>
                    <div className="time-info">
                      {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                      <p id="total-title">Total Time:</p>
                      <p id="total-content">
                        {acf.total_time ? acf.total_time : '-'}
                      </p>
                    </div>
                    <div className="time-info">
                      {/* <img src={acf.thumbnail.source_url} alt="imageIcon" /> */}
                      <p id="serve-title">Serves:</p>
                      <p id="serve-content">
                        {acf.number_served ? acf.number_served : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget-section">
                <div id="shareWidgetDiv">
                  <ShareWidget
                    className="widget"
                    fbMessengerPageName={title}
                    hideAPinButton={true}
                    pinterestTitle={title}
                    pinterest_image_url={
                      acf.thumbnail !== null
                        ? this.state.isPreviewMode
                          ? acf.thumbnail.url
                          : acf.thumbnail.source_url
                        : PlaceHolderImage
                    }
                  />
                </div>
                <div>
                  <a
                    className="pin-link"
                    data-pin-custom="true"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.pinterest.com/pin/create/button/?url=${decodeURIComponent(
                      this.state.currentUrl
                    )}&media=${
                      acf.thumbnail !== null
                        ? this.state.isPreviewMode
                          ? acf.thumbnail.url
                          : acf.thumbnail.source_url
                        : PlaceHolderImage
                    }&description=${title}`}
                  >
                    <div className="button">
                      <FontAwesomeIcon icon={faPinterestP} /> &nbsp;Pin this
                      Recipe
                    </div>
                  </a>

                  <FlyoutBasic
                    styles={{
                      visible:
                        !this.state.isAuthed &&
                        this.state.showNoAuthFlyout === 'favoritesNoAuth1',
                      height: '250px'
                    }}
                    onClose={this.toggleShowNoAuthFlyout.bind(
                      this,
                      'favoritesNoAuth1'
                    )}
                    locationId="recipe-detail-widget-flyout"
                  >
                    <FlyoutContentNotAuthed title="Save & Favorite Recipes" />
                  </FlyoutBasic>
                </div>

                <div
                  className="button favorite"
                  onClick={this.toggleFavorite.bind(this, 'favoritesNoAuth1')}
                >
                  <FontAwesomeIcon icon={faHeart} />
                  &nbsp;
                  {isFavorited ? (
                    <span> Remove from Favorites</span>
                  ) : (
                    <span>Save as Favorite</span>
                  )}
                </div>

                {/* <div className="button download">
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 12"
                  >
                    <path
                      d="M7,1039.36v6.32l-1.7-1.37l-0.62,0.78l2.82,2.27l2.82-2.27l-0.62-0.78L8,1045.66v-6.3H7z M3,1047.36v1h9v-1H3z"
                      transform="translate(0 -1038.362)"
                    />
                  </svg>
                  &nbsp;Download
                </div> */}
                {this.state.viewPort === 'desktop' ? (
                  <div
                    className="button print"
                    onClick={e =>
                      this.printModal(e, this.props.data.wordpressWpRecipes)
                    }
                  >
                    <FontAwesomeIcon icon={faPrint} />
                    &nbsp;Print this Recipe
                  </div>
                ) : null}
              </div>

              <button id="shopping-list-open" onClick={this.openShopHandler}>
                Show Shopping List &nbsp;
                <svg
                  id="caret"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 307.054 307.054"
                >
                  <path
                    d="M302.445,205.788L164.63,67.959c-6.136-6.13-16.074-6.13-22.203,0L4.597,205.788c-6.129,6.132-6.129,16.069,0,22.201 l11.101,11.101c6.129,6.136,16.076,6.136,22.209,0l115.62-115.626L269.151,239.09c6.128,6.136,16.07,6.136,22.201,0 l11.101-11.101C308.589,221.85,308.589,211.92,302.445,205.788z"
                    id="_x34_85._Up"
                  />
                </svg>
              </button>
            </TopSection>
            <div className="recipe-sub-nav" id="subNav">
              <div className="sub-nav-container">
                <div
                  // to={`/recipes/${slug}/#hero-section`}
                  className="nav-item to-top"
                  onClick={() => {
                    if (this.state.isBrowser) {
                      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                        // smooth scroll to selected section
                        const target = document.querySelector(`#hero-section`);
                        target.scrollIntoView();
                      } else {
                        // smooth scroll to selected section
                        const target = document.querySelector(`#hero-section`)
                          .offsetTop;
                        window.scrollTo({
                          top: target - 85,
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  Back to top &nbsp;&nbsp;
                  <svg
                    id="caret"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 307.054 307.054"
                  >
                    <path
                      d="M302.445,205.788L164.63,67.959c-6.136-6.13-16.074-6.13-22.203,0L4.597,205.788c-6.129,6.132-6.129,16.069,0,22.201 l11.101,11.101c6.129,6.136,16.076,6.136,22.209,0l115.62-115.626L269.151,239.09c6.128,6.136,16.07,6.136,22.201,0 l11.101-11.101C308.589,221.85,308.589,211.92,302.445,205.788z"
                      id="_x34_85._Up"
                    />
                  </svg>
                </div>
                <div
                  className="nav-item"
                  // to={`/recipes/${slug}/#ingredient-section`}
                  onClick={() => {
                    if (this.state.isBrowser) {
                      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#ingredient-section`
                        );
                        target.scrollIntoView();
                      } else {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#ingredient-section`
                        ).offsetTop;
                        window.scrollTo({
                          top: target - 125,
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  Ingredients
                </div>
                <div
                  className="nav-item"
                  onClick={() => {
                    if (this.state.isBrowser) {
                      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#instruction-container`
                        );
                        target.scrollIntoView();
                      } else {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#instruction-container`
                        ).offsetTop;
                        window.scrollTo({
                          top: target - 175,
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  Instructions
                </div>
                <div
                  className="nav-item"
                  onClick={() => {
                    if (this.state.isBrowser) {
                      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#related-recipes`
                        );
                        target.scrollIntoView();
                      } else {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#related-recipes`
                        ).offsetTop;
                        window.scrollTo({
                          top: target - 245,
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  Related Recipes
                </div>
                {/* <div
                  className="nav-item"
                  onClick={() => {
                    if (this.state.isBrowser) {
                      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                        // smooth scroll to selected section
                        const target = document.querySelector(
                          `#review-section`
                        );
                        target.scrollIntoView();
                      } else {
                        // smooth scroll to selected section
                        const target = document.querySelector(`#review-section`)
                          .offsetTop;
                        window.scrollTo({
                          top: target - 275,
                          behavior: 'smooth'
                        });
                      }
                    }
                  }}
                >
                  Reviews
                </div> */}
              </div>
            </div>
            <ShoppingListDrawer
              className="shopping-list-drawer-wrapper"
              isShopOpen={this.state.isShopOpen}
              clicked={this.openShopHandler}
              user={this.state.user}
            />
            <ShoppingListStateConsumer>
              {shoppingListContext => {
                return (
                  <RecipeSection>
                    <div className="ingredient-container">
                      <div id="ingredient-section">
                        <div className="ingredients">
                          <h2>Ingredients</h2>
                          <ul>{this.renderIngredients()}</ul>
                          <button
                            id="deselect-button"
                            onClick={() => {
                              this.setState({
                                itemsToAdd: []
                              });
                            }}
                          >
                            Deselect All
                          </button>
                          {/* <h4 className="save">
                            Save, Print, and Email Ingredients
                          </h4>
                          <p id="clickToSavePrintEmail">
                            Click below to save, print, and email ingredients
                            from your shopping list.
                          </p>
                          <button
                            className="add-to-list"
                            onClick={() => {
                              this.setState({ isShopOpen: true });
                              shoppingListContext.addItems(
                                this.state.itemsToAdd
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add to shopping
                            list
                          </button> */}
                        </div>
                        <div className="product-image">
                          {relatedProducts ? (
                            <div className="product-image-container">
                              <Link
                                to={`/products/${relatedProducts[0].node.slug}`}
                              >
                                <img
                                  src={
                                    relatedProducts[0].node.acf.product_image
                                      .source_url
                                  }
                                  alt="product-img"
                                />
                                <div
                                  className="ps-widget"
                                  ref={this.psWid}
                                  ps-sku={
                                    relatedProducts[0].node.acf.product_sku
                                  }
                                />
                                <div id="product-title">
                                  {ReactHtmlParser(
                                    relatedProducts[0].node.title
                                  )}
                                </div>
                              </Link>
                              <WhereToBuy
                                siteTitle={this.props.siteTitle}
                                skuNum={relatedProducts[0].node.acf.product_sku}
                              />
                            </div>
                          ) : (
                            <div className="product-image-container">
                              <img src={placeHolderImage} alt="product-img" />
                              <div id="product-title">No Related Products</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </RecipeSection>
                );
              }}
            </ShoppingListStateConsumer>
            <InstructionSection className="instruction-section">
              <div id="instruction-container">
                <div class="title-instructions">Instructions</div>
                <p id="instructionsSubheading">
                  Tap or click steps to mark as complete
                </p>
                <div className="left-align">
                  <div className="steps">{this.renderInstruction()}</div>
                  <hr />

                  <div className="tips">
                    {acf.recipe_tips && acf.recipe_tips != '-' ? (
                      <>
                        <h3>Recipe Tips</h3>
                        <p id="recipeTipPara">
                          {ReactHtmlParser(acf.recipe_tips)}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <h4>Tags</h4>
                  <div id="tags">
                    {tags.map(el => (
                      <div key={el.name} id="tag">
                        {el.name.replace('&amp;', '&')}
                      </div>
                    ))}
                  </div>

                  <div className="button-container">
                    <WhiteButton className="button">
                      <a
                        className="pin-link"
                        data-pin-custom="true"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.pinterest.com/pin/create/button/?url=${decodeURIComponent(
                          this.state.currentUrl
                        )}&media=${
                          acf.thumbnail !== null
                            ? this.state.isPreviewMode
                              ? acf.thumbnail.url
                              : acf.thumbnail.source_url
                            : PlaceHolderImage
                        }&description=${title}`}
                      >
                        <FontAwesomeIcon icon={faPinterestP} /> &nbsp;Pin this
                        Recipe
                      </a>
                    </WhiteButton>
                    <div className="favorite-button">
                      <span className="inline">
                        <FlyoutBasic
                          styles={{
                            visible:
                              !this.state.isAuthed &&
                              this.state.showNoAuthFlyout ===
                                'favoritesNoAuth2',
                            height: '250px'
                          }}
                          onClose={this.toggleShowNoAuthFlyout.bind(
                            this,
                            'favoritesNoAuth2'
                          )}
                          locationId="recipe-detail-btnCont-flyout"
                        >
                          <FlyoutContentNotAuthed title="Save & Favorite Recipes" />
                        </FlyoutBasic>
                      </span>

                      <WhiteButton
                        className="button"
                        onClick={this.toggleFavorite.bind(
                          this,
                          'favoritesNoAuth2'
                        )}
                      >
                        <FontAwesomeIcon icon={faHeart} />
                        &nbsp;
                        {isFavorited ? (
                          <span>Remove from Favorites</span>
                        ) : (
                          <span>Save as Favorite</span>
                        )}
                      </WhiteButton>
                    </div>

                    {this.state.viewPort === 'desktop' ? (
                      <WhiteButton
                        className="button"
                        onClick={e =>
                          this.printModal(e, this.props.data.wordpressWpRecipes)
                        }
                      >
                        <FontAwesomeIcon icon={faPrint} /> &nbsp;Print this
                        Recipe
                      </WhiteButton>
                    ) : null}
                  </div>
                </div>
              </div>
            </InstructionSection>
            <RelatedSection className="related-section-wrapper">
              <div id="related-recipes" className="anchor-position" />
              <div className="small-img" />
              <h2>{ReactHtmlParser(related_recipe_heading)}</h2>
              <h3>{ReactHtmlParser(related_recipe_sub_heading)}</h3>

              {this.state.isBrowser && this.state.viewPort === 'mobile' ? (
                <Swiper {...params}>{relatedRecipeArr.slice(0, 4)}</Swiper>
              ) : (
                <div id="related-recipes">{relatedRecipeArr.slice(0, 4)}</div>
              )}

              <StyledLink link="/recipe-search">See All Recipes</StyledLink>
            </RelatedSection>
            {/* <ReviewsSection
              type={'recipes'}
              slug={slug}
              authenticatedUser={this.props.userContext.authenticatedUser}
            /> */}
            <RecipeSearchWidget
              background={search_widget_image.source_url}
              changeHandler={this.onChangeHandler}
              clicked={this.submitHanler}
            />

            <PdModal
              isModalOpen={this.state.isVideoModalOpen}
              hideModal={() => this.hideModal('VideoModal')}
            >
              {this.isValidYouTubeUrl(acf.recipe_video_id) ? (
                <iframe
                  width="600"
                  height="400"
                  frameBorder="0"
                  src={this.parseYouTubeURL(acf.recipe_video_id)}
                />
              ) : null}
            </PdModal>
          </Div>
        )}
      </>
    );
  }
}

export default RecipeDetail;

export const query = graphql`
  query($slug: String!) {
    allWordpressWpRecipes {
      edges {
        node {
          wordpress_id
          title
          slug
          main_ingredient
          acf {
            total_time
            thumbnail {
              source_url
            }
          }
          tags {
            name
          }
        }
      }
    }
    allWordpressWpMainIngredient {
      edges {
        node {
          wordpress_id
          slug
        }
      }
    }
    wordpressWpRecipes(slug: { eq: $slug }) {
      slug
      wordpress_id
      title
      content
      tags {
        name
      }
      yoast_head_json {
        title
        description
      }
      acf {
        prep_time
        total_time
        number_served
        recipe_video_id
        thumbnail {
          source_url
        }
        related_product {
          post_name
        }
        ingredient_products {
          basic_ingredient
        }
        instructions {
          step
        }
        recipe_tips
      }
    }
    allWordpressWpProducts {
      edges {
        node {
          title
          slug
          acf {
            product_sku
            product_image {
              source_url
            }
          }
        }
      }
    }
    wordpressWpOptionsPage(slug: { eq: "site-options" }) {
      acf {
        facebook_url
        instagram_url
        youtube_url
        twitter_url
        pinterest_url
      }
    }
    wordpressPage(slug: { eq: "recipe-detail-template" }) {
      acf {
        related_recipe_heading
        related_recipe_sub_heading
        search_widget_image {
          source_url
        }
      }
    }
  }
`;
