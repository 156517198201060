import React from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';

const SEO = ({ seoInfo }) => {
  return (
    <Helmet>
      {/* PriceSpider Tags if needed */}
      <meta name="ps-key" content={process.env.PS_KEY} />
      <meta name="ps-country" content="US" />
      <meta name="ps-language" content="en" />
      {seoInfo.psKey ? (
        <script
          src="https://cdn.pricespider.com/1/lib/1.12.1/ps-widget.js"
          async
        />
      ) : (
        <meta name="ps-loaded" content="no" />
      )}

      {/* General tags */}
      <title>{ReactHtmlParser(seoInfo.title)}</title>
      <meta name="description" content={seoInfo.description} />
      <meta name="image" content={seoInfo.image} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={seoInfo.url} />
      <meta property="og:type" content={seoInfo.type} />

      <meta property="og:title" content={seoInfo.ogtitle} />
      <meta property="og:description" content={seoInfo.description} />
      <meta property="og:image" content={seoInfo.image} />

      {/* Twitter Card tags */}
      <meta name="twitter:title" content={seoInfo.title} />
      <meta name="twitter:description" content={seoInfo.description} />
      <meta name="twitter:image" content={seoInfo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seoInfo.twitter} />
    </Helmet>
  );
};

export default SEO;
